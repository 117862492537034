import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ArticleBody = ({ items, pageUrl }) => {
  const faq = items.reduce(
    (acc, item) => {
      if (item.ldJsonQuestion && item.ldJsonAnswer) {
        acc.mainEntity.push({
          '@type': 'Question',
          name: item.ldJsonQuestion,
          acceptedAnswer: {
            '@type': 'Answer',
            text: item.ldJsonAnswer,
          },
        });
      }

      return acc;
    },
    {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [],
    }
  );

  return (
    <Container className="blog-container">
      {faq.mainEntity.length > 0 && (
        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faq),
          }}
          type="application/ld+json"
        />
      )}

      {items.map((item) => {
        const {
          id,
          typeHandle,
          heading = false,
          headingId = null,
          headingClass = null,
          sectionHeading = false,
          pullQuote = false,
          image = false,
          text = false,
          quote = false,
          attribution = false,
          buttonTitle = false,
          buttonType = false,
          buttonUrl = false,
          table = false,
          tablecolumncount = false,
          stepimage = false,
          stepname = false,
          stepnameasheading = false,
          stepnametext = false,
          stepurl = false,
          stepitemhowtodirection = false,
          stepitemhowtotip = false,
        } = item;

        if (typeHandle === 'text') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0 text-wrap">
                <div
                  className="pr-xl-13"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'heading') {
          return (
            <Row key={id} id={headingId} className={headingClass}>
              <Col className="mb-7 mb-lg-0">
                <h3>{heading}</h3>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'image') {
          if (!image[0]) {
            console.error(`No image data on ${pageUrl}`);
            return null;
          }

          const { localFile, title, url } = image[0];
          const gatsbyImage = getImage(localFile);

          return (
            <Row key={id} className="mb-10 d-flex justify-content-center">
              <Col
                className="mb-7 mb-lg-0 col-lg-10"
                itemScope={true}
                itemType="https://schema.org/ImageObject"
              >
                <meta itemProp="name" content={title} />
                {gatsbyImage && (
                  <GatsbyImage
                    alt={title}
                    image={gatsbyImage}
                    itemProp="contentUrl"
                  />
                )}

                {!gatsbyImage && url && (
                  <img
                    src={url}
                    alt={title}
                    width="100%"
                    itemProp="contentUrl"
                  />
                )}
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'button') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                <a href={buttonUrl} className={'btn ' + buttonType}>
                  {buttonTitle}
                </a>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'quote') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                <blockquote>
                  <div className="flex">
                    <p>{quote}</p>
                    {attribution && (
                      <footer>
                        – <cite>{attribution}</cite>
                      </footer>
                    )}
                  </div>
                </blockquote>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'supertable') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                <table className="table table-bordered superTable">
                  <tbody>
                    {table.map((row) => {
                      const tds = [];
                      Array.from({ length: tablecolumncount }).forEach(
                        (_, i) => {
                          tds.push(
                            <td
                              key={i + 'sdfsdf'}
                              dangerouslySetInnerHTML={{
                                __html: row['column' + (i + 1)],
                              }}
                            />
                          );
                        }
                      );
                      return <tr key={row.id}>{tds}</tr>;
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'stepitemhowto') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                {stepitemhowtodirection && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: stepitemhowtodirection,
                    }}
                  />
                )}
                {stepitemhowtotip && (
                  <div dangerouslySetInnerHTML={{ __html: stepitemhowtotip }} />
                )}
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'newSection') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                <hr className="" />
                <h2>{sectionHeading}</h2>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'pullQuote') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                <blockquote className="blockquote">
                  <p className="mb-0">{pullQuote}</p>
                </blockquote>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'pullQuote') {
          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                <blockquote className="blockquote">
                  <p className="mb-0">{pullQuote}</p>
                </blockquote>
              </Col>
            </Row>
          );
        }

        if (typeHandle === 'stepitem') {
          const tmpStepItemImage = stepimage && stepimage[0];
          const gatsbyImage =
            tmpStepItemImage && getImage(tmpStepItemImage.localFile);

          return (
            <Row key={id} className="mb-10">
              <Col className="mb-7 mb-lg-0">
                {stepname &&
                  (stepnameasheading ? (
                    <h2 className="">
                      <span id="stepurl">{stepname}</span>
                    </h2>
                  ) : (
                    <p id={stepurl}>{stepname}</p>
                  ))}

                {stepnametext && (
                  <div dangerouslySetInnerHTML={{ __html: stepnametext }} />
                )}

                {tmpStepItemImage && (
                  <div className="d-flex justify-content-center">
                    <div
                      className="lightBoxGallery col-lg-10"
                      itemScope={true}
                      itemType="https://schema.org/ImageObject"
                    >
                      <meta itemProp="name" content={tmpStepItemImage.title} />
                      <a
                        href={tmpStepItemImage.localFile.publicURL}
                        title={tmpStepItemImage.title}
                        data-gallery=""
                      >
                        {gatsbyImage && (
                          <GatsbyImage
                            alt={tmpStepItemImage.title}
                            image={gatsbyImage}
                            itemProp="contentUrl"
                          />
                        )}
                      </a>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          );
        }

        if (typeHandle && typeof window === 'undefined') {
          console.error(`Not handled typeHandle "${typeHandle}" on ${pageUrl}`);
        }

        return null;
      })}
    </Container>
  );
};
export default ArticleBody;
