import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Link from '../i18n/Link';

import img404 from '../assets/image/spyserp/404.jpeg';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-10 pt-lg-15 bg-default-1 position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="10" xl="9" data-aos="fade-up" data-aos-duration="1000">
            <div className="hero-content section-title text-center">
              <p>
                <img src={img404} className="img404" alt="" />
              </p>

              <h1 className="gr-text-2 font-weight-bold mb-12">404</h1>

              <p className="gr-text-8">
                <Link
                  to="/"
                  className="btn btn-primary"
                  role="button"
                  aria-expanded="false"
                >
                  {t('NotFound.ctaText')}
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
