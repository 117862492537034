import React from 'react';
import Page from '../components/Blog/Page';
import { graphql } from 'gatsby';

const PagePageWrapper = (props) => {
  return <Page propsData={props} />;
};

export default PagePageWrapper;

export const pagePageQuery = graphql`
  query pagePageQuery($slug: String!, $siteId: Int!) {
    craftPagePageEntry(slug: { eq: $slug }, siteId: { eq: $siteId }) {
      id
      featuredImage {
        id
        url
        title
        ... on Craft_imagesEn_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        ... on Craft_imagesRu_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
      title
      shortDescription
      heading
      metaDescription
      metaKeywords
      metaNoindex
      metaTitle
      articleBody {
        ... on Craft_articleBody_newSection_BlockType {
          id
          typeHandle
          sectionHeading
          isLocked
        }
        ... on Craft_articleBody_heading_BlockType {
          id
          typeHandle
          isLocked
          heading
        }
        ... on Craft_articleBody_text_BlockType {
          id
          position
          text
          typeHandle
          isLocked
        }
        ... on Craft_articleBody_pullQuote_BlockType {
          id
          isLocked
          pullQuote
          position
          typeHandle
        }
        ... on Craft_articleBody_image_BlockType {
          id
          caption
          image {
            url
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          isLocked
          position
          typeHandle
        }
        ... on Craft_articleBody_quote_BlockType {
          id
          isLocked
          typeHandle
          quote
          position
        }
        ... on Craft_articleBody_button_BlockType {
          id
          isLocked
          title
          buttonTitle
          buttonType
          buttonUrl
          align
          typeHandle
        }
        ... on Craft_articleBody_textCenteredHighlighted_BlockType {
          id
          isLocked
          typeHandle
          text
        }
        ... on Craft_articleBody_supertable_BlockType {
          id
          table {
            ... on Craft_table_BlockType {
              column1
              column2
              column3
              id
            }
          }
          tablecolumncount
          typeHandle
          isLocked
        }
        ... on Craft_articleBody_domaininput_BlockType {
          id
          domaininputtitle
          typeHandle
        }
        ... on Craft_articleBody_stepitem_BlockType {
          id
          stepimage {
            url
            id
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          stepname
          stepnameasheading
          stepnametext
          stepurl
          typeHandle
        }
        ... on Craft_articleBody_stepitemhowto_BlockType {
          id
          stepitemhowtodirection
          stepitemhowtotip
          typeHandle
        }
        ... on Craft_articleBody_stepitemtool_BlockType {
          id
          typeHandle
          steptool
        }
      }
    }
  }
`;
