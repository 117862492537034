import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Row, Col, Container } from 'react-bootstrap';
import NotFound from '../NotFound';
import MetaTags from '../Seo/MetaTags';
import ArticleBody from './ArticleBody';
import { usePageContext } from '../../i18n/PageContext';

const Page = ({
  propsData: {
    data: { craftPagePageEntry },
  },
}) => {
  const { getPageUrl } = usePageContext();
  if (!craftPagePageEntry) {
    return <NotFound />;
  }

  const featuredImage = craftPagePageEntry.featuredImage[0];
  const gatsbyImage = featuredImage && getImage(featuredImage.localFile);
  const pageUrl = getPageUrl();

  return (
    <>
      <MetaTags
        title={
          craftPagePageEntry.metaTitle
            ? craftPagePageEntry.metaTitle
            : craftPagePageEntry.title
        }
        description={craftPagePageEntry.metaDescription}
        keywords={craftPagePageEntry.metaKeywords}
        image={gatsbyImage ? gatsbyImage.images.fallback.src : ''}
      />

      <Container>
        <Row className="justify-content-center my-md-6 py-24 pt-ly-29">
          <Col lg="9" xl="8">
            <div className="px-md-12 text-center">
              <h1 className="title gr-text-2">{craftPagePageEntry.title}</h1>

              <div
                className="gr-text-8 mb-0"
                dangerouslySetInnerHTML={{
                  __html: craftPagePageEntry.shortDescription,
                }}
              />
            </div>
          </Col>
        </Row>

        {gatsbyImage && (
          <Row className="justify-content-center">
            <Col
              xs="12"
              itemScope={true}
              itemType="https://schema.org/ImageObject"
            >
              <meta itemProp="name" content={craftPagePageEntry.title} />
              <GatsbyImage
                loading="eager"
                alt={craftPagePageEntry.title}
                image={gatsbyImage}
                itemProp="contentUrl"
              />
            </Col>
          </Row>
        )}
      </Container>

      <div className="about-content pt-lg-10 pt-5 pb-13 pb-lg-25">
        <ArticleBody items={craftPagePageEntry.articleBody} pageUrl={pageUrl} />
      </div>
    </>
  );
};

export default Page;
